<template>
	<a
		href="javascript:void(0)"
		class="flex items-center"
		@click="() => clickLink()"
	>
		<span
			class="flex-grow"
			:class="props.itemBold ? 'font-bold' : ''"
		>
			<slot />
		</span>
		<Icon
			v-if="props?.subitem"
			name="md-chevron_right"
		/>
	</a>
</template>

<script lang="ts" setup>
interface MenuLinkProps {
	link?: string;
	onClick?: () => void;
	subitem?: boolean;
	itemBold?: boolean;
}
const props = defineProps<MenuLinkProps>();

const router = useRouter();
const clickLink = () => {
	if (props.link) {
		router.push(props.link);
	} else if (props.onClick) {
		return props.onClick();
	}
};
</script>

<style lang="scss" scoped></style>
