<template>
	<div class="footer-payment">
		<div class="info">
			<span class="title">{{ $t("components.footer.payment.s1_title") }}</span
			><br />
			<span class="subtitle">{{ $t("components.footer.payment.s1_subtitle") }}</span>
		</div>
		<div class="info">
			<span class="title">{{ $t("components.footer.payment.s2_title") }}</span
			><br />
			<span class="subtitle">{{ $t("components.footer.payment.s2_subtitle") }}</span>
		</div>
		<div class="info">
			<span class="title">{{ $t("components.footer.payment.s3_title") }}</span
			><br />
			<TengivaSupportedPayMethods class="flex justify-center" />
		</div>
	</div>
	<div class="main-footer">
		<div class="footer-logo">
			<NuxtLink :to="organizationProfile?.slug ? '/' + organizationProfile.slug : '/'">
				<img
					v-if="logoUrl !== ''"
					:src="logoUrl"
					class="h-6"
				/>
				<div v-else>
					<span class="org_title">{{ organizationProfile?.public_name }}</span>
				</div>
			</NuxtLink>
		</div>
		<!-- <div>Custom Message</div> -->
		<div class="footer-links">
			<div class="link-section">
				<b>{{ publicName }}</b>
				<br />
				<span
					v-if="publicAddress"
					class="contact_details"
				>
					{{ publicAddress?.address }} - {{ publicAddress?.address_additional }}<br />
					{{ publicAddress?.city }} {{ publicAddress?.zip }} <br />
					{{ publicAddress?.country }}<br />
					<br />
				</span>
				{{ website }}<br />
			</div>
			<div class="link-section">
				<span
					><b>{{ $t("components.footer.sections.company") }}</b></span
				><br />
				<NuxtLink
					v-if="organizationProfile?.slug"
					:to="`/${organizationProfile.slug}/about-us`"
					class="cursor-pointer"
					>{{ $t("components.navigations.label.about_us") }}</NuxtLink
				>
				<br />
				<NuxtLink
					v-if="organizationProfile?.slug"
					:to="`/${organizationProfile.slug}/contact`"
					class="cursor-pointer"
					>{{ $t("components.navigations.label.contact") }}</NuxtLink
				>
			</div>
			<div class="link-section">
				<span
					><b>{{ $t("components.footer.sections.quick_links") }}</b></span
				><br />
				<NuxtLink
					to="/auth"
					class="cursor-pointer"
					>{{ $t("components.navigations.label.login") }}</NuxtLink
				>
				<br />
				<NuxtLink
					to="/auth?tab=sign_up&accountType=business"
					class="cursor-pointer"
					>{{ $t("components.navigations.label.create_account") }}</NuxtLink
				>
			</div>
			<div class="link-section">
				<span
					><b>{{ $t("components.footer.sections.support_links") }}</b></span
				><br />
				<NuxtLink
					v-if="organizationProfile?.slug"
					:to="`/${organizationProfile.slug}/quality-guidelines`"
					class="cursor-pointer"
					>{{ $t("components.navigations.label.quality_guidelines") }}</NuxtLink
				>
				<br />
				<NuxtLink
					v-if="organizationProfile?.slug"
					:to="`/${organizationProfile.slug}/secured-payment-methods`"
					class="cursor-pointer"
					>{{ $t("components.navigations.label.secured_payment_methods") }}</NuxtLink
				>
				<br />
				<NuxtLink
					v-if="organizationProfile?.slug"
					:to="`/${organizationProfile.slug}/shipping-return-policies`"
					class="cursor-pointer"
					>{{ $t("components.navigations.label.shipping_return_policies") }}</NuxtLink
				>
				<br />
				<NuxtLink
					v-if="organizationProfile?.slug"
					:to="`/${organizationProfile.slug}/how-to-sell`"
					class="cursor-pointer"
					>{{ $t("components.navigations.label.how_to_sell") }}</NuxtLink
				>
			</div>
		</div>
	</div>
	<div class="footer_wrapper">
		<div class="footer">
			<div class="left flex_grow">
				<img
					v-if="showDMixFooterLogo"
					class="max-w-[170px] w-full h-auto"
					src="/images/logo-dm.png"
				/>

				<template v-else>
					<span class="text-[12px] mr-[4px]">Powered by</span>
					<TengivaLogo
						color="black"
						class="w-[118px] h-[24px] mt-[4px]"
					/>
				</template>
			</div>
			<div class="middle flex_grow">© {{ getCurrentYear() }} All rights reserved</div>
			<div class="right flex_grow">
				<NuxtLink
					v-if="organizationProfile?.slug"
					:to="`/${organizationProfile.slug}/terms-of-services`"
					class="cursor-pointer"
					>{{ $t("components.navigations.label.terms_of_services") }}</NuxtLink
				>
				<span class="spacer">|</span>
				<NuxtLink
					v-if="organizationProfile?.slug"
					:to="`/${organizationProfile.slug}/privacy-policy`"
					class="cursor-pointer"
					>{{ $t("components.navigations.label.privacy_policy") }}</NuxtLink
				>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import TengivaLogo from "../TengivaLogo.vue";
import type { Address } from "@/types/addresses";

const { organizationProfile } = storeToRefs(useOrgStore());
const { getPlatformPublicAddressAddress } = useABMApi();

const { mode } = useRuntimeConfig().public;
const showDMixFooterLogo = computed(() => mode !== "dev" && organizationProfile.value?.slug === "demo-showroom-dmix");

const logoUrl = ref("");
const { getPlatformSettingsPlatform } = useECMApi();
const { imgixEcmWpcDomain } = useRuntimeConfig().public;

const publicName = computed(() => organizationProfile.value?.public_name);
const website = computed(() => organizationProfile.value?.organization?.online?.website);

const publicAddress = ref<Address>();

watchEffect(async () => {
	if (organizationProfile.value?.organization_id) {
		const { response } = await getPlatformSettingsPlatform(organizationProfile.value.organization_id);
		logoUrl.value = `https://${imgixEcmWpcDomain}/${response.value?.data?.platform_settings?.[0]?.id}/${response.value?.data?.platform_settings?.[0]?.platform_branding?.logo}`;

		const { response: addressData, error } = await getPlatformPublicAddressAddress(
			organizationProfile.value.organization_id
		);
		if (error.value) {
			throw new Error("Failed to fetch public address");
		}
		publicAddress.value = addressData.value?.data?.find((address: Address) => address.public_address);
	}
});
</script>
<style lang="scss" scoped>
.footer_wrapper {
	@apply h-[80px] border-t border-neutral-100;
}
.footer {
	@apply flex items-center justify-between h-[80px] px-[40px] max-w-[1440px] mx-auto;
	@apply text-[12px] text-neutral-900 leading-[18px] font-normal;
	@apply mobile:h-[158px] mobile:flex-col mobile:py-[25px];

	.flex_grow {
		@apply flex flex-1 items-center;
	}

	.left {
		@apply justify-start;
	}

	.middle {
		@apply justify-center;
	}

	.right {
		@apply justify-end;
		.spacer {
			@apply text-neutral-200 mx-[4px];
		}
	}
}

.footer-payment {
	background-color: #f0f0f2;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 20px;
	width: 100%;
	flex-wrap: wrap;

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
		padding-left: 20px;
		gap: 0px;
	}

	.info {
		padding: 20px;
		text-align: center;
		min-width: 150px;

		@media (max-width: 768px) {
			text-align: left;
			padding-left: 0;
		}

		.title {
			color: var(--Neutral-Black, #050a19);
			font-size: 16px;
			font-weight: 500;
		}

		.subtitle {
			color: var(--Neutral-Grey-08, #46464b);
			font-size: 14px;
			font-weight: 400;
		}
	}
}

.main-footer {
	padding: 40px;

	.footer-logo {
		margin-bottom: 40px;
	}

	.footer-links {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 20px;

		@media (max-width: 1024px) {
			flex-direction: column;
			flex-wrap: wrap;
			gap: 20px;
		}

		@media (max-width: 768px) {
			flex-direction: column;
			gap: 15px;
		}

		.link-section {
			flex: 1;
			min-width: 250px;

			@media (max-width: 1024px) {
				min-width: 250px;
				flex: 1;
			}

			@media (max-width: 768px) {
				min-width: auto;
				flex: 1;
			}
		}
	}
}
</style>
