<template>
	<div class="top_bar_wrapper">
		<div class="top_bar">
			<div class="powered_by">
				{{ msgStandardNav }}
				<!-- <span class="text-[12px] mr-[2px]">Powered by</span>
				<TengivaLogo
					color="black"
					class="w-[72px] h-[14px] mt-[4px]"
				/> -->
			</div>
			<div class="items">
				<div>
					<a
						v-if="organizationProfile?.slug"
						:href="`/${organizationProfile.slug}/about-us`"
						>About us</a
					>
				</div>
				<div>
					<a
						v-if="organizationProfile?.slug"
						:href="`/${organizationProfile.slug}/contact`"
						>Contact</a
					>
				</div>
				<!-- <div class="flex items-center">
					English
					<Icon
						name="md-expand_more"
						class="mt-[3px]"
					/>
				</div> -->
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
// import TengivaLogo from "../TengivaLogo.vue";
const orgStore = useOrgStore();
const { organizationProfile } = storeToRefs(orgStore);

const msgStandardNav = ref("");
const { getPlatformSettingsPlatform } = useECMApi();

watchEffect(async () => {
	if (organizationProfile.value?.organization_id) {
		const { response } = await getPlatformSettingsPlatform(organizationProfile.value?.organization_id as string);
		msgStandardNav.value =
			response.value?.data?.platform_settings?.[0]?.platform_branding?.custom_standard_titlebar_msg || "";
	}
});
</script>
<style lang="scss" scoped>
.top_bar_wrapper {
	@apply h-[30px] w-full bg-neutral-50 fixed top-0 mobile:hidden;
	.top_bar {
		@apply h-[30px] flex justify-between items-center pl-[40px] pr-[34px] max-w-[1440px] mx-auto;

		.powered_by {
			@apply flex items-center text-neutral-900;
		}

		.items {
			@apply text-[14px] leading-[20px] flex items-center text-neutral-700 gap-x-[24px] mobile:hidden;

			div {
				@apply cursor-pointer;
			}
		}
	}
}
</style>
