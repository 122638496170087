<template>
	<div class="navigation-footnote">
		<span
			class="text"
			:class="{ hidden: props?.hideText || false }"
			>Powered by</span
		>

		<TengivaLogo
			class="logo"
			color="black"
			:icon-only="props?.logoIconOnly || false"
		/>
	</div>
</template>

<script lang="ts" setup>
import TengivaLogo from "@/components/TengivaLogo.vue";
interface FooterProps {
	logoIconOnly?: boolean;
	hideText?: boolean;
}

const props = defineProps<FooterProps>();
</script>

<style lang="scss" scoped>
.navigation-footnote {
	@apply flex justify-center h-[72px] items-center;
	.text {
		@apply self-center leading-[24px] text-[12px] mr-[3px];
	}
	.logo {
		@apply w-auto h-[24px] flex;
	}
}
</style>
